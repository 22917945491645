/*
|==========================================================================
| jQuery TablePress
|==========================================================================
| 
| This function cleans up the default TablePress implementation if using
| the TablePress plugin, adding accessibility and a responsive layout.
|
|==========================================================================
|
*/

function tablepress() {

	// Table Clean Up
	jQuery('.tablepress').attr('summary','Comparison Table');
	jQuery('.tablepress').attr('aria-readonly','true');
	jQuery('.tablepress th').attr('role','columnheader');
	jQuery('.tablepress td').attr('role','gridcell');

	jQuery("th").each(function(i){
		if(jQuery(this).is(':empty') || jQuery(this).html() === '&nbsp;'){
			jQuery(this).html('<span class="show-for-sr">Account Description Row</span>');
		}
	});

	jQuery('.aria-hidden-true').attr('aria-hidden','true');

	// Create Accessible Content Container for Table Lists
	jQuery('<div class="show-for-sr tablepress-account-comparison-table-accessible"><h3>Account Types</h3></div>').insertBefore('.tablepress-account-comparison-table');

	// Get Account Types
	var account_titles = jQuery('.tablepress-account-comparison-table th:not(.column-1)').each(function(i){
		var this_text = jQuery.trim(jQuery(this).text());
		var this_class = jQuery(this).attr('class');
		if(this_text != '') { 
			var column_Object = new Object();
			column_Object.name = this_text;
			column_Object.class = this_class;

			// Parse Account Attributes
			var account_attributes = [];
			jQuery('.tablepress-account-comparison-table td.'+this_class).each(function(i){
				var column_label = jQuery.trim(jQuery(this).parent().find('.column-1').text());
				var column_text = jQuery.trim(jQuery(this).html());
				if(column_label === '') {
					column_label = 'Account Description';
				}
				var column_string = '<span class="field-label">'+column_label + ':</span> ' + column_text; 
				var column_class = jQuery(this).attr('class');
				if(column_text != '') { 
					account_attributes.push( column_string );
				}
			});

			column_Object.values = account_attributes;

			var account_html;
			account_html += "<ul>";
			account_html += '<li>' + column_Object.name + '</li>';
			jQuery.each(column_Object.values, function (index, item) {
				account_html += "<li>" + item + "</li>";
			});
			account_html += "</ul>";
			var account_html_clean = account_html.replace("undefined", "");
			jQuery(".tablepress-account-comparison-table-accessible").append(account_html_clean);
		}
	});

	table_appearance_rendering(table_parent_class);
	table_appearance_hover(table_parent_class);

};