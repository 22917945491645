/*
|==========================================================================
| Device Detection Scripts
|==========================================================================
| 
| Various functions for helpful device detection.
|
|==========================================================================
|
*/

// Detect Native Android Browser
function detect_native_android_browser() {
  var ua = navigator.userAgent;
  var is_native_android = ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if(is_native_android === true && isChrome === false) {
    jQuery('body').addClass('native-android-browser');
  }
}

// Detect Internet Explorer
function detect_IE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  var trident = ua.indexOf('Trident/');
  if (msie > 0) {
        // IE 9 or older => return version number
        var isIE = document.all && !window.atob;
        if(isIE) {
          jQuery('body').addClass('ie-9-or-older');
          // if (window.location.href !== "/browser-outdated/") {
              // window.location="/browser-outdated/";
          // }
        }
      }
      if (trident > 0) {
        // IE 11 (or newer) => return version number
        var rv = ua.indexOf('rv:');
        jQuery('body').addClass('ie-11-or-newer');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
    // other browser
    return false;
  }

// Detect Apple devices
function apple_device_detect() {
  var iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
  if(iOS === true) {
    jQuery('body').addClass('apple-device');
  } 
}

function iPhone_notch() {
  // Really basic check for the ios platform
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Get the device pixel ratio
  var ratio = window.devicePixelRatio || 1;

  // Define the users device screen dimensions
  var screen = {
    width : window.screen.width * ratio,
    height : window.screen.height * ratio
  };

  // iPhone X Detection
  if ((iOS && screen.width == 1125 && screen.height === 2436) || iOS && screen.width == 1242 && screen.height === 2688)  {

    // Set a global variable now we've determined the iPhoneX is true
    window.iphoneX = true;

    // Adds a listener for ios devices that checks for orientation changes.
    window.addEventListener('orientationchange', update);
    update();
  }

  // Each time the device orientation changes, run this update function
  function update() {
    notch();
    iphoneXChecker();
  }

  // Notch position checker
  function notch() {

    var _notch = '';

    if( 'orientation' in window ) {
      // Mobile
      if (window.orientation == 90) {
        _notch = 'left';
      } else if (window.orientation == -90) {
        _notch = 'right';
      }
    } else if ( 'orientation' in window.screen ) {
      // Webkit
      if( screen.orientation.type === 'landscape-primary') {
        _notch = 'left';
      } else if( screen.orientation.type === 'landscape-secondary') {
        _notch = 'right';
      }
    } else if( 'mozOrientation' in window.screen ) {
      // Firefox
      if( screen.mozOrientation === 'landscape-primary') {
        _notch = 'left';
      } else if( screen.mozOrientation === 'landscape-secondary') {
        _notch = 'right';
      }
    }

    window.notch = _notch;
  }

  if ( window.iphoneX === true ) {
    jQuery('body').addClass('iphoneX');
    jQuery('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1.0, viewport-fit=cover');
  } else {
    jQuery('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1.0');
  }

}

// Bespoke functions:
// The above functions have no jQuery Dependencies.
// The below code uses jQuery solely for this quick demo.

function iphoneXChecker() {
  if (window.notch == 'left') {
    jQuery('body').removeClass('notch-right').addClass('notch-left');
  } else if (window.notch == 'right') {
    jQuery('body').removeClass('notch-left').addClass('notch-right');
  } else {
    jQuery('body').removeClass('notch-right notch-left');
  }
}
