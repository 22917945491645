/*
|==========================================================================
| Bind AJAX links
|==========================================================================
| 
| Needs description.
|
|==========================================================================
|
*/

// Binds links in selector to call content via ajax
function bind_ajax_links() {

  var selector = ".menu-contents ul li a, header.article-header h2 a, .sidebar .widget ul li a, .story-list a, .article-title-bottom a";

  jQuery(selector).unbind( "click" );
  jQuery(selector).click(function(event){

    event.preventDefault();

    jQuery("#epic-nav").addClass('activated');

    jQuery('.menu-contents ul li').removeClass('current-menu-item');
    jQuery(this).parent().addClass('current-menu-item');

    var linktoload = jQuery(this).attr('href');

    // Verify String
    if (typeof linktoload !== typeof undefined && linktoload !== false) {
      // Redirects 
      external_redirect(linktoload);
      // Fetch content
      load_content(linktoload);
    }
  });

}

// Ajax load the content
function load_content(linktoload) {

  // Scroll to top of page
  jQuery('html, body').animate({scrollTop:0}, 1000);

  // Loading animation
  jQuery("#ajax-content").removeClass('visible');
  jQuery("#ajax-content").addClass('loading');

  jQuery('.loader_vertbar').addClass('loading');

  setTimeout(function(){

    jQuery.get(linktoload)
      .success(function(data, textStatus, jqXHR){

        var data = data.replace('<body', '<body><div id="body"').replace('</body>', '</div></body>').replace('<style type="text/css" data-type="vc_shortcodes-custom-css"','<style type="text/css" id="vc_shortcodes-custom-css" data-type="vc_shortcodes-custom-css"').replace('<div id="ajax-content" ','<div id="ajax-content" ');

        var vc_css = jQuery(data).filter('#vc_shortcodes-custom-css');
        var body = jQuery(data).filter('#body');
        var child_nodes = body[0].childNodes;

      var ajax_html = '';
      for (var i = 0; i < child_nodes.length; i++) {
        var child_node_content = child_nodes[i].innerHTML;
        if(child_node_content !== undefined && child_node_content !== '' && child_node_content.indexOf('skrollr-body') > -1) {
          ajax_html = child_node_content; 
            // console.log(ajax_html);
        }       
      }

        var linktoload_classes = body[0].className;

        jQuery('body').removeClass();
        jQuery('body').addClass(linktoload_classes);

        jQuery('*[data-type="vc_shortcodes-custom-css"]').remove();

        if(vc_css[0] !== undefined) { 
          jQuery('head').append(vc_css[0]);
      }
        
        close_epic_menu();

      jQuery( "#ajax-content" ).load( linktoload + " #ajax-content > *",function(){

        // jQuery( ".landing-page-menu" ).load( linktoload + " .landing-page-menu > *",function(){ /* ... */ });
        // jQuery( ".download-link" ).load( linktoload + " .download-link > *",function(){ /* ... */ });

        // skrollr_init(); 
        epic_title_size();
        bind_ajax_links();
        isotope_masonry();
        jQuery(window).trigger('resize');
        
        jQuery('.loader_vertbar').removeClass('loading');
        jQuery('.loader_vertbar').addClass('loaded');

        setTimeout(function(){

          jQuery("#ajax-content").removeClass('loading');
          jQuery("#ajax-content").addClass('loaded');
    
          jQuery(window).trigger('resize');

          jQuery('.loader_vertbar').removeClass('loaded');

          jQuery("#ajax-content").removeClass('loaded');
          jQuery("#ajax-content").addClass('visible');

          jQuery("#content").addClass('visible');

        },350);
    
      });

      });

  },400);

}

// If if a link is not pointing to this domain, open in a new window
function external_redirect(linktoload) {
  var search_dev_domain = linktoload.search("volum8v2016.wpengine.com");
  var search_live_domain = linktoload.search("volum8.co");
  if((search_dev_domain > -1) || (search_live_domain > -1)) {
    // Local, okay to proceed
    history.pushState({}, '', linktoload);
  } else {
    // External, open window
    window.open(linktoload,'_blank');
    return false;
  }
}

// Load Content via Popstate
function load_content_via_popstate() {
  load_content(window.location);
}

// Back and Forward Browser Support
// if(detectIE()) { } else {
  // window.addEventListener('popstate', function(event) {
    // console.log('popstate fired!');
    // load_content(window.location);
  // });
// }


