/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

jQuery(document).ready(function () {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect Internet Explorer
  detect_IE();

  // Detect Native Android Browser
  detect_native_android_browser();

  // Apple Device Detect
  apple_device_detect();

  // iPhone Xseries
  iPhone_notch();

  // Enable AOS for use out of the box
  AOS.init({
    disable: 'mobile'
  });

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  jQuery('.page-template-template-3-0-meeting-hearings .views-as button:not(.viewlist), .tax-meetings_hearings_cat .views-as button:not(.viewlist), .post-type-archive-meetings_hearings .views-as button:not(.viewlist), .page-template-template-5-0-projects .views-as button:not(.viewlist), .tax-projects_cat .views-as button:not(.viewlist), .tax-projects_status .views-as button:not(.viewlist), .tax-projects_year .views-as button:not(.viewlist), .post-type-archive-projects .views-as button:not(.viewlist)').click(function (event) {

    jQuery('.news-container article').addClass('medium_to_large-6');

    jQuery('.page-template-template-3-0-meeting-hearings .views-as button, .tax-meetings_hearings_cat .views-as button, .post-type-archive-meetings_hearings .views-as button, .page-template-template-5-0-projects .views-as button, .tax-projects_cat .views-as button, .tax-projects_year .views-as button, .tax-projects_status .views-as button, .post-type-archive-projects .views-as button').removeClass('active');

    jQuery(this).addClass('active');


  });

  jQuery('.page-template-template-3-0-meeting-hearings .views-as button.viewlist, .tax-meetings_hearings_cat .views-as button.viewlist, .post-type-archive-meetings_hearings .views-as button.viewlist, .page-template-template-5-0-projects .views-as button.viewlist, .tax-projects_cat .views-as button.viewlist, .tax-projects_year .views-as button.viewlist, .tax-projects_status .views-as button.viewlist, .post-type-archive-projects .views-as button.viewlist').click(function (event) {

    jQuery('.news-container article').removeClass('medium_to_large-6');

    jQuery('.page-template-template-3-0-meeting-hearings .views-as button, .tax-meetings_hearings_cat .views-as button, .post-type-archive-meetings_hearings .views-as button, .page-template-template-5-0-projects .views-as button, .tax-projects_cat .views-as button, .tax-projects_year .views-as button, .tax-projects_status .views-as button, .post-type-archive-projects .views-as button').removeClass('active');

    jQuery(this).addClass('active');

  });

  // Event Target Testing
  jQuery(document).click(function (event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function (event) {
    sticky_header();
  });

  sticky_header();

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

  // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass('end');

  // Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function () {
    if (jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  jQuery('document').ready(function($) {

    var pathname = window.location.pathname;
    $past = $('#pastMeetings');
    $current = $('#currentMeetings');
  
    $past.click(function() {
      window.location = pathname+'?filter=past';
    });
  
    $current.click(function() {
      window.location = pathname+'?filter=current';
    });
  
  });

});
