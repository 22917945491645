/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function sticky_header() {

    var window_height = jQuery(window).height();
    var element_height = jQuery(".top-bar").height();
    var element_title_height = jQuery(".menu-bar").height();
    var current_scroll_position = jQuery(window).scrollTop();
    var element_calc = (element_title_height + 100);

    //console.log('Element_calc: ' +element_calc);
    //console.log('Window_height: ' +window_height);
    //console.log(current_scroll_position);
    
    if (current_scroll_position >= element_height) {
        jQuery('header.header').addClass('sticky');
        jQuery('body').addClass('header-is-sticky');
    } else {
        jQuery('header.header').removeClass('sticky');
        jQuery('body').removeClass('header-is-sticky');
    }

    if (current_scroll_position >= element_calc) {
        jQuery('.contact-button, .menu-bar').addClass('slide-left');
    } else {
        jQuery('.contact-button, .menu-bar').removeClass('slide-left');
    }
}